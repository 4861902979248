import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleCategory from './categories/moduleDataList.js'
import moduleProduct from './products/moduleDataList.js'
import moduleCoupon from './coupons/moduleDataList.js'
import moduleDelivery from './delivery-areas/moduleDataList.js'
import moduleClients from './clients/moduleDataList.js'
import moduleOrders from './orders/moduleDataList.js'
import moduleUsers from './users/moduleDataList.js'
import modulePromotions from './promotions/moduleDataList.js'
import moduleColors from './colors/moduleDataList.js'
import moduleAuth from './auth/moduleAuth.js'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    categories: moduleCategory,
    products: moduleProduct,
    coupons: moduleCoupon,
    deliveries: moduleDelivery,
    clients: moduleClients,
    auth: moduleAuth,
    orders: moduleOrders,
    users: moduleUsers,
    promotions: modulePromotions,
    colors: moduleColors
  },
  strict: false
})
