export default {
  fetchColors({commit}, data) {
    commit('SET_COLORS', data.colors)
  },
  addColor({commit}, data) {
    commit('ADD_ITEM', data.color)
  },
  updateColor({commit}, data) {
    commit('UPDATE_COLOR', data.color)
  },
}
