export default {
  ADD_ITEM(state, item) {
    state.colors.unshift(item)
  },
  SET_COLORS(state, colors) {
    state.colors = colors
  },
  UPDATE_COLOR(state, color) {
    const objectIndex = state.colors.findIndex((c) => c.id === color.id)
    Object.assign(state.colors[objectIndex], color)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.colors.findIndex((c) => c.id === itemId)
    state.colors.splice(ItemIndex, 1)
  }
}
