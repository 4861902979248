import firebase from 'firebase/app'

// Initialize Firebase
let config = {
  apiKey: "AIzaSyDH_xVGk2Ybqnc8EFWIx7SsTwyazL7SjtQ",
  authDomain: "kubi-e7feb.firebaseapp.com",
  projectId: "kubi-e7feb",
  storageBucket: "kubi-e7feb.appspot.com",
  messagingSenderId: "661020084578",
  appId: "1:661020084578:web:dd5aa6d6fbdd2e80e5443b",
  measurementId: "G-0MVGMRCLX9"
};
firebase.initializeApp(config)
