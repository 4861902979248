export default {
  fetchCategories({commit}, data) {
    commit('SET_CATEGORIES', data.categories)
  },
  addCategory({commit}, data) {
    commit('ADD_ITEM', data.category)
  },
  updateCategories({commit}, data) {
    commit('UPDATE_CATEGORY', data.category)
  },
}
